import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

#--- Esto es para poder usar getters en las clases de coffeescript ---#|
Function::getter = (prop, get) ->                                     #|  
  Object.defineProperty @prototype, prop, { get, configurable: yes }  #|
#---------------------------------------------------------------------#!

export default class extends Controller
  # @values: {columns: Array}

  formSend: (eve) -> 
    setTimeout(
      -> 
        button = document.getElementById('the-submitter')
        button.disabled = false
        eve.target.reset()
      5000
    )
    
  # disconnect: ->
