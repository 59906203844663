import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['master']

  setVictims: () ->
    { data } = this
    showVictim = data.get 'victimShow'
    toRemove = data.get 'typeEstate'
    id = @masterTarget.value
    if (id == toRemove)
      $(showVictim).removeClass("uk-hidden")
    else
      $(showVictim).addClass("uk-hidden")

    connect: () ->
      checker = parseInt @masterTarget.value
      @setVictims() if checker
