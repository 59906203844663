import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller
  initialize: ->
    config = {
      altInput: true
      altFormat: "Y"
      dateFormat: "YYYY"
      minDate: '2000-01'
      maxDate: 'today'
    }
    flatpickr @element, config
