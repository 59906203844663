import {Controller} from "stimulus"
import {slideshow} from 'uikit'

export default class extends Controller
  @targets = ['parent']

  connect: ->
    mq_mobile = window.matchMedia('(max-width: 720px)')
    mq_mobile.addListener @initializeSlideshow
    @initializeSlideshow(mq_mobile)

  initializeSlideshow: (media_query) ->
    slideshow @parentTarget, {
      autoplay: true,
      autoplayInterval: 4000,
      ratio: false
    }
