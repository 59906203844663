import {Controller} from "stimulus"
import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls';

swalDefaultConfig =
  cancelButtonText: 'Cancelar'
  showCancelButton: true
  allowOutsideClick: false
  inputAttributes:
    autocapitalize: 'off'
  customClass:
    confirmButton: 'uk-button black-button uk-margin-small-right'
    cancelButton: 'uk-button blue-button'
  buttonsStyling: false
export default class extends Controller
  connect: ->
    # Obtener dataset
    @product = @.data.get 'product'
    @price = @.data.get 'price'
    @price_cop = @.data.get 'price_cop'
    @price_usd = @.data.get 'price_usd'
    @limit = @.data.get 'limit'
    @image = @.data.get 'image'
    @title = @.data.get 'title'
    @coin = @.data.get 'coin'
  add: ->
    limit = parseInt @.data.get('limit')
    request_swal = Swal.fire {
      swalDefaultConfig...
      title: "Cuantas unidades desea añadir "
      text: "Unidades disponibles: #{limit}",
      input: 'number'
      validationMessage: "Número de unidades no válido (Unidades disponibles: #{limit}) "
      confirmButtonText: 'Añadir al carrito'
      allowOutsideClick: false
      inputValue: 1
      inputAttributes:
        min: 1
        max: limit
    }
    request_swal.then ({value}) =>
      if value?
        @quantity = value
        final_data = {
          title: @title,
          image: @image,
          limit: parseInt(@limit),
          price: @price
          price_cop: @price_cop
          price_usd: @price_usd,
          quantity: parseInt(@quantity),
          idproduct: parseInt(@product)
        }
        AxiosRequest('/customers/shopping_carts/add.json', {product: @product, quantity: parseInt(value)}, 'post').then ({data}) =>
          ls = new SecureLS();
          actual_cart = ls.get('cart');
          uid = @.data.get('uid')
          # incializador cart
          if !actual_cart
            actual_cart = {}
          if !actual_cart[uid]
            actual_cart[uid] = []
          # verificamos que el producto que estamos agreagando exita en el carrito
          cart_some = actual_cart[uid].some((item) => (item.idproduct == parseInt(@product)))
          if cart_some
            # si exite el producto lo recorremos y actulizamos las unidades
            for item, value of actual_cart[uid]
              if value.idproduct == parseInt @product
                # validar que la cantidad no sea mayor a la limite
                if value.quantity < parseInt @limit
                  value.quantity += parseInt @quantity
                  Swal.fire({
                    title: data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    allowOutsideClick: false
                    footer:"
                      <div class='uk-flex uk-flex-wrap uk-flex-around'>
                        <a class='uk-button black-button uk-margin-right' href='/customers/shopping_carts?coin=#{@coin}'>Ir al carrito</a>
                        <a class='uk-button blue-button' href='/productos?coin=#{@coin}'>Ver más productos</a>
                      </div>
                    "
                  })
                else
                  Swal.fire({
                    icon: 'error',
                    title: "Error",
                    text: "Número de unidades no válido (Unidades disponibles: #{parseInt @limit})"
                  })
          else
            # sino lo agregamos al carrito
            actual_cart[uid].push(final_data)
            Swal.fire({
              title: data.message,
              icon: 'success',
              showConfirmButton: false,
              allowOutsideClick: false
              footer:"
                <div class='uk-flex uk-flex-wrap uk-flex-around'>
                  <a class='uk-button black-button uk-margin-right' href='/customers/shopping_carts?coin=#{@coin}'>Ir al carrito</a>
                  <a class='uk-button blue-button' href='/productos?coin=#{@coin}'>Ver más productos</a>
                </div>
              "
            })
          ls.set('cart', actual_cart)
