import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import { getMetaValue, toArray, findElement, removeElement, insertAfter } from "../../utils/helpers"
import Dropzone from 'dropzone'

Function::getter = (prop, get) ->
  Object.defineProperty @prototype, prop, { get, configurable: yes }

export default class extends Controller
  @targets: ["input"]

  connect: ->
    Dropzone.autoDiscover = false
    @dropZone = createDropZone(this)
    @hideFileInput()
    @bindEvents()
    setTimeout(@setServerImages,500)

  disconnect: ->
    @dropZone.destroy()
# Private
  setServerImages: =>
    images = JSON.parse @data.get('images')
    for image in images
      mockFile = { name: image.name, size: image.size, blob_id: image.id }
      @addCustomFile mockFile, image.url, { status: 'success' }

  addCustomFile: (file, thumbnail_url, response) ->
    @dropZone.files.push file
    @dropZone.emit "addedfile", file
    @dropZone.emit "thumbnail", file, thumbnail_url
    @dropZone.emit "processing", file
    @dropZone.emit "success", file, response, false
    @dropZone.emit "complete", file

  hideFileInput: ->
    @inputTarget.disabled = true
    @inputTarget.style.display = "none"

  bindEvents: ->
    @dropZone.on 'addedfile', (file) =>
      setTimeout (=> file.accepted && createDirectUploadController(this, file).start()), 500
    @dropZone.on 'removedfile', (file) =>
      if file.controller
#       axios.defaults.headers.delete['X-CSRF-Token'] = getMetaValue("csrf-token");
#       axios.delete 'delete_image.json', { params: { blob_id: file.blob_id } }
        removeElement(file.controller.hiddenInput)
      else
        hiddenInput = document.querySelector("input[value='#{file.blob_id}']")
        removeElement hiddenInput

    @dropZone.on 'canceled', (file) =>
      file.controller && file.controller.xhr.abort()

  @getter 'headers', -> { "X-CSRF-Token": getMetaValue("csrf-token") }
  @getter 'url', -> @inputTarget.getAttribute "data-direct-upload-url"
  @getter 'maxFiles', -> @data.get("maxFiles") || 15
  @getter 'maxFileSize', -> @data.get("maxFileSize") || 10
  @getter 'acceptedFiles', -> @data.get("acceptedFiles") || 'image/png, image/jpeg'
  @getter 'addRemoveLinks', -> @data.get("addRemoveLinks") || true

########################################################################################################################

class DirectUploadController
  constructor: (source, file) ->
    @directUpload = createDirectUpload file, source.url, this
    @source = source
    @file = file

  start: ->
    @file.controller = this
    @hiddenInput = @createHiddenInput()
    @directUpload.create (error, attributes) =>
      if error
        removeElement @hiddenInput
        @emitDropzoneError error
      else
        @hiddenInput.value = attributes.signed_id
        @emitDropzoneSuccess()

# Private
  createHiddenInput: ->
    input = document.createElement 'input'
    input.type = 'hidden'
    input.name = @source.inputTarget.name
    insertAfter input, @source.inputTarget
    input

  directUploadWillStoreFileWithXHR: (xhr) ->
    @bindProgressEvent(xhr)
    @emitDropzoneUploading()

  bindProgressEvent: (xhr) ->
    @xhr = xhr
    @xhr.upload.addEventListener "progress", (event) => @uploadRequestDidProgress(event)

  uploadRequestDidProgress: (event) ->
    element = @source.element
    progress = event.loaded / event.total * 100
    findElement(@file.previewTemplate, ".dz-upload").style.width = "#{progress}%"

  emitDropzoneUploading: ->
    @file.status = Dropzone.UPLOADING
    @source.dropZone.emit "processing", @file

  emitDropzoneError: (error) ->
    @file.status = Dropzone.ERROR
    @source.dropZone.emit 'error', @file, error
    @source.dropZone.emit 'complete', @file

  emitDropzoneSuccess: ->
    @file.status = Dropzone.SUCCESS
    @source.dropZone.emit "success", @file
    @source.dropZone.emit "complete", @file

########################################################################################################################

createDirectUploadController = (source, file) ->
  new DirectUploadController source, file

createDirectUpload = (file, url, controller) ->
  new DirectUpload file, url, controller

createDropZone = (controller) ->
  new Dropzone controller.element, {
    url: controller.url
    headers: controller.headers
    maxFiles: controller.maxFiles
    maxFilesize: controller.maxFileSize
    acceptedFiles: controller.acceptedFiles
    addRemoveLinks: controller.addRemoveLinks
    autoQueue: false
    dictRemoveFile: 'Eliminar'
  }

########################################################################################################################
