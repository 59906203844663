import { Controller } from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  deletefavourite: ->
    idfavourite = @data.get('idfavourite')
    Swal.fire(
      title: '¿Estás seguro?'
      text: '¡No podrás revertir esto!'
      icon: 'warning'
      showCancelButton: true
      confirmButtonColor: '#3085d6'
      cancelButtonColor: '#d33'
      confirmButtonText: 'Sí, bórralo!').then (result) ->
        if result.value
          AxiosRequest("/customers/favourites/#{idfavourite}", {}, 'delete').then ({data}) ->
            Swal.fire('Eliminado!', data.message, 'success').then ->
              Turbolinks.visit(window.location)
        else if result.dismiss == Swal.DismissReason.cancel
          Swal.fire('Cancelado', 'Tu obra de arte está segura', 'error').then ->
            Turbolinks.visit(window.location)
        return
