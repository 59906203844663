import { Controller } from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  myfavourite_lot: ->
    idpicture = @data.get('lot_id')
    idcustomer = @data.get('idcustomer')
    dat = favourite_lot: {lot_id: idpicture, customer_id: idcustomer}
    AxiosRequest('/customers/favourite_lots', dat, 'post').then ({data}) ->
      Swal.fire('Listo', data.message, 'success').then ->
        console.info 'success'
