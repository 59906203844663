require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require 'trix'

import UiKit from 'uikit'
import UiIcons from 'uikit/dist/js/uikit-icons.min'

UiKit.use(UiIcons)
# Stimulus Controllers
import 'controllers/user'
# stimulus Controllers
import 'controllers/admin'
# Componentes de view components
import "../components"
#import "stylesheets"
window.Swal = require 'sweetalert2/dist/sweetalert2.min'
