import axios from 'axios'

# NOTE
# ESTA CLASE DEPENDE DE QUE EL TOKEN EXISTA
# REVISAR PRIMERO QUE EL CONTROLADOR COMPLETE SU TRABAJO

urls = {
  col: 'https://api.mercadolibre.com/sites/MCO',
  auth: 'https://auth.mercadolibre.com.co/authorization',
  items: 'https://api.mercadolibre.com/items',
  users: 'https://api.mercadolibre.com/users'
}

Function::getter = (prop, get) ->
  Object.defineProperty @prototype, prop, {get, configurable: yes}

export default class Mercadolibre
  # ANCHOR CONSTRUCTOR
  constructor: ({meliId, redirectUrl, secret, appId, moken}) ->
    @appId = appId
    @meliId = meliId
    @redirectUrl = redirectUrl
    @secret = secret
    @moken = JSON.parse moken

  # ANCHOR HEADERS
  @getter 'headers', -> headers: {Authorization: "Bearer #{@moken.access_token}"}

  # ANCHOR GET TOKEN
  getToken: (productId) ->
    url = new URL urls.auth
    url.searchParams.set('response_type', 'code')
    url.searchParams.set('client_id', @appId)
    url.searchParams.set('redirect_uri', @redirectUrl)
    url.searchParams.set('state', productId) if productId
    window.location.replace url
  
  # ANCHOR GET LISTING TYPES
  getListingTypes: -> 
    @makeRequest axios.get("#{urls.col}/listing_types")

  # ANCHOR GET USER PRODUCTS
  getUser: ->
    @makeRequest axios.get("#{urls.users}/me", @headers)

  # ANCHOR GET USER PRODUCTS
  getUserProducts: ->
    @makeRequest axios.get("#{urls.users}/#{@moken.user_id}/items/search?status=active", @headers)

  # ANCHOR GET CATEGORIES
  getCategories: ({keyword}) ->
    return false unless keyword
    @makeRequest axios.get("#{urls.col}/domain_discovery/search?q=#{keyword}")

  # ANCHOR GET PRODUCTS BY IDS
  getProductsByIds: (ids) ->
    return false unless ids
    @makeRequest axios.get("#{urls.items}?ids=#{ids.join(',')}", @headers)

  # ANCHOR PUBLISH PRODUCT
  publishProduct: (product) ->
    return false unless product
    @makeRequest axios.post(urls.items, product, @headers)

  # ANCHOR PUBLISH DESCRIPTION
  publishDescription: (description, itemId) ->
    return false unless description
    @makeRequest axios.post(
      "#{urls.items}/#{itemId}/description"
      {plain_text: description}
      @headers
    )

  # ANCHOR VOLVER A PUBLICAR
  reList: (product) ->
    return false unless product
    @makeRequest axios.post("#{urls.items}/#{product.id}/relist", product, @headers)

  # ANCHOR EDITAR PRODUCTO
  editProduct: (product) ->
    return false unless product
    @makeRequest axios.put(urls.items, product, @headers)

  # ANCHOR MAKE REQUEST
  makeRequest: (request) ->
    try
      response = await request
      response.data
    catch error 
      @errorsChecker error

  # ANCHOR VALIDATION ERRORS
  validationErrors: (error) ->
    switch error.code
      when 'moderations.seller_id.not_authorized'
        'Marca de publicación limitada: Para poder ofrecer sus productos en Mercado Libre debes ser un vendedor acreditado.'
      when 'item.start_time.invalid'
        'El campo hora de inicio no se puede actualizar.'
      when 'item.category_id.invalid'
        'Categoría no encontrada o no permitida.'
      when 'item.buying_mode.invalid'
        'La categoría no permite el tipo de publicación seleccionada'
      when 'item.attributes.missing_required'
        '<strong>Atributos - Categoría:</strong> Faltan atributos necesarios para esta categoría'
      when 'item.listing_type_id.invalid'
        'Tipo de publicación no válido'
      when 'item.listing_type_id.requiresPictures'
        'Las imágenes son obligatorias para el tipo de publicación seleccionada.'
      when 'item.site_id.invalid'
        'Sitio no válido'
      when 'item.description.max'
        'Cantidad de caracteres superada para la descripción'
      when 'item.pictures.max'
        'Límite de imágenes superado para esta publicación'
      when 'item.attributes.invalid_length'
        'Un atributo supera la longitud máxima permitida'
      when 'item.available_quantity.invalid'
        '<strong>Cantidad:</strong> Para publicaciones gratuitas solo se permite una unidad'
      when 'item.listing_type_id.unavailable'
        '<strong>Tipo de publicación:</strong> La categoría no permite publicaciones gratuitas'
      when 'item.condition.invalid'
        '<strong>Categoría - Tipo de publicación:</strong> La categoría solo permite productos nuevos'
      else 
        "Error no identificado: #{error.code}"
        
  # ANCHOR BAD REQUEST
  badRequestErrorsHandler: (theError) ->
    return false unless theError

    errorsListed = []

    switch theError.error
      when 'validation_error'
        return false unless theError.cause.length

        errorsListed.push(@validationErrors(error) for error in theError.cause)
      else 
        console.info 'Sin errores'

    mappedErrors = errorsListed.map (item) => "<li>#{item}</li>"
    Swal.fire {
      icon: 'error',
      title: 'Error al publicar producto',
      html: "<ul class='uk-list uk-list-disc uk-list-collapse uk-text-left'>
        #{mappedErrors.join(' ')}
      </ul>"
    }

  # ANCHOR FORBIDDEN
  forbiddenErrorsHandler: (errors) ->
    switch errors.message
      when 'seller.unable_to_list'
        Swal.fire {
          icon: 'error',
          title: 'Error al publicar',
          text: 'Debes completar los datos de tu cuenta de Mercadolibre para poder publicar productos',
          footer: '<a href="https://mercadolibre.com.co" target="_blank" rel="noreferrer noopener">
            Ir a Mercadolibre
          </a>'
        }
      else 
        console.info "Error no identificado: #{error.message}"

  # ANCHOR ERRORS CHECKER
  errorsChecker: (error) ->
    theError = error.response.data

    switch theError.status
      when 400
        @badRequestErrorsHandler(theError)
      when 401
        console.log 'Problema al renovar las llaves de acceso'
      when 403
        @forbiddenErrorsHandler(theError)
      else 
        console.info 'Código de error no contemplado'
