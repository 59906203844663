import {Controller} from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  connect: ->
    @extra3 = @data.get 'extra3'
    if @extra3 == 'carrito'
      @epayco_form = {
        name: @data.get 'name'
        description: @data.get 'description'
        invoice: @data.get 'invoice'
        currency: @data.get 'currency'
        amount: @data.get 'amount'
        tax_base: @data.get 'tax-base'
        tax: @data.get 'tax'
        country: @data.get 'country'
        response: @data.get 'response'
        confirmation: @data.get 'confirmation'
        extra1: @data.get 'extra1'
        extra2: @data.get 'extra2'
        extra3: @extra3
        p_split_merchant_receiver: @data.get 'p_split_merchant_receiver'
        p_split_primary_receiver: @data.get 'p_split_primary_receiver'
        p_split_primary_receiver_fee: @data.get 'p_split_primary_receiver_fee'
        p_split_type: @data.get 'p_split_type'
        split_rule: @data.get 'split_rule'
        p_split_receivers: @data.get 'p_split_receivers'
        name_billing: @data.get 'name-billing'
        address_billing: @data.get 'address-billing'
        external: 'true'
      }
    else
      @epayco_form = {
        name: @data.get 'name'
        description: @data.get 'description'
        invoice: @data.get 'invoice'
        currency: @data.get 'currency'
        amount: @data.get 'amount'
        tax_base: @data.get 'tax-base'
        tax: @data.get 'tax'
        country: @data.get 'country'
        response: @data.get 'response'
        confirmation: @data.get 'confirmation'
        extra1: @data.get 'extra1'
        extra2: @data.get 'extra2'
        extra3: @extra3
        name_billing: @data.get 'name-billing'
        address_billing: @data.get 'address-billing'
        external: 'true'
      }
  cartLocker: ->
    @uid = @.data.get 'uid'
    @is_lot = @.data.get 'lot_id'
    if @is_lot
      @epaycoCall()
    else
      AxiosRequest("/customers/#{@uid}/shopping_carts/lock.json",
        {
          emeritus: '2016'
          customer: @uid
          shopping_cart: @.data.get 'scid'
        },
        'post'
      ).then => @epaycoCall()
  epaycoCall: ->
    Swal.fire({
      icon: 'success',
      title: 'Un momento por favor...',
      text: 'Será redirigido a la tienda. Un momento por favor...',
      timer: 10000,
      timerProgressBar: true,
      showConfirmButton: false
    })
    handler = ePayco.checkout.configure {
      key: @data.get 'key'
      test: "false"
    }
    handler.open(@epayco_form)
