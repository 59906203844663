import {Controller} from "stimulus"
import getQueryParams from '../../utils/get-query-params'
import SecureLS from 'secure-ls'

export default class extends Controller
  @targets = ['table']
  connect: ->
    ref_payco = getQueryParams('ref_payco')
    urlapp = "https://secure.epayco.co/validation/v1/reference/#{ref_payco}"
    $.get urlapp, (response) =>
      if response.success
        # Borramos la que hay en el ls
        if response.data.x_extra3 == 'carrito'
          ls = new SecureLS()
          ls.remove('cart')
          # borramos el promocionalcode cuando todo termine
          ls.remove('discount')
        translations = [
          {original_name: 'x_transaction_date', humanized_name: 'Fecha'}
          {original_name: 'x_response', humanized_name: 'Respuesta'}
          {original_name: 'x_id_invoice', humanized_name: 'Referencia'}
          {original_name: 'x_response_reason_text', humanized_name: 'Motivo'}
          {original_name: 'x_transaction_id', humanized_name: 'Recibo'}
          {original_name: 'x_bank_name', humanized_name: 'Banco'}
          {original_name: 'x_approval_code', humanized_name: 'Autorización'}
          {original_name: 'x_amount', humanized_name: 'Total'}
        ]
        index = 0
        for key,value of response.data
          for translation in translations
            {original_name, humanized_name} = translation
            if original_name == key
              row = @tableTarget.insertRow(index)
              name_cell = row.insertCell(0)
              value_cell = row.insertCell(1)
              name_cell.innerText = humanized_name
              value_cell.innerText = value
              index++
      if response.data.x_cod_response == 1
        console.log 'transacción aceptada'
      if response.data.x_cod_response == 2
        console.log 'transacción rechazada'
      if response.data.x_cod_response == 3
        console.log 'transacción pendiente'
      if response.data.x_cod_response == 4
        console.log 'transacción fallida'
