import { Controller } from 'stimulus'
import 'datatables.net-buttons-dt'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-colreorder-dt'

$.fn.dataTable = require 'datatables.net-dt'
$.fn.dataTable.ext.classes.sPageButton = 'uk-button uk-button-small uk-button-secondary';

#--- Esto es para poder usar getters en las clases de coffeescript ---#|
Function::getter = (prop, get) ->                                     #|  
  Object.defineProperty @prototype, prop, { get, configurable: yes }  #|
#---------------------------------------------------------------------#!

export default class extends Controller
  @values: { columns: Array, user: Number, model: String, options: Object }

  connect: -> @initDatatable()

  initDatatable: ->
    theSelector = this.element.querySelector 'table'
    unless $.fn.dataTable.isDataTable(theSelector)
      url = if @optionsValue?.url then @optionsValue.url else "/admin/#{ @userValue }/#{ @modelValue }/datatable.json"
      csrf = document.querySelector('[name="csrf-token"]').content

      @datatable = $(theSelector).dataTable({
        ajax:
          url: url,
          method: 'post'
          headers: {'X-CSRF-Token': csrf}
        columns: @columns_mapped_to_datatable
        initComplete: ->
          $('select[name="DataTables_Table_0_length"]').addClass('uk-select')
          $('div.dataTables_filter input').addClass('uk-input');
          @api().columns().every -> 
            this1 = this
            $('input', @footer()).on 'keyup change clear', -> 
              this1.search(@value).draw() if this1.search() isnt @value
        processing: true
        serverSide: true
        colReorder: true
        searchDelay: 800
        stateSave: true
        dom: '<"uk-flex uk-padding-small uk-flex-between uk-flex-wrap"fBl>rt<"uk-flex uk-padding-small uk-flex-between uk-flex-wrap"ip>'
        buttons: [
          {extend: 'colvis', className: 'uk-button uk-button-secondary uk-button-small'}
          {extend: 'copy', className: 'uk-button uk-button-secondary uk-button-small'}
          {extend: 'excel', exportOptions: {columns: ':visible'}, className: 'uk-button uk-button-secondary uk-button-small'}
          {extend: 'print', exportOptions: {columns: ':visible'}, className: 'uk-button uk-button-secondary uk-button-small'}
        ]
        pagingType: 'full_numbers'
        scrollY:        '60vh',
        scrollX: true
        scrollCollapse: true,
        language:
          stripClasses: ''
          emptyTable: 'No hay datos aquí'
          search: '<span uk-search-icon></span>'
          infoFiltered: 'Filtrado entre _MAX_ registros'
          searchPlaceholder: 'Buscar...'
          processing: '<span uk-spinner="ratio: 4.5"></span>'
          info: '<span class="uk-label blue-button">_START_ - _END_ </span> de <span class="uk-label blue-button">_TOTAL_</span>'
          lengthMenu: '_MENU_'
          infoEmpty: 'Sin datos por aquí...'
          zeroRecords: 'Sin resultados'
          paginate:
            previous: '<span uk-icon="icon: chevron-left"></span>'
            next: '<span uk-icon="icon: chevron-right"></span>'
            first: '<span uk-icon="icon: chevron-double-left"></span>'
            last: '<span uk-icon="icon: chevron-double-right"></span>'
          buttons:
            copy: '<span uk-icon="icon: copy"></span> Copiar'
            colvis: '<span uk-icon="icon: expand"></span> Columnas'
            print: '<span uk-icon="icon: print"></span> Imprimir'
            excel: '<span uk-icon="icon: download"></span>.xlsx'
      })

  @getter 'table_headers', ->
    @columnsValue.map (column) -> column.visible_name || column.name

  @getter 'columns_mapped_to_datatable', ->
    @columnsValue.map (column) ->
      parsedColumn = {data: column.name}
      Object.assign(parsedColumn, column.options) if column.options
      parsedColumn

  disconnect: ->
    @datatable.fnClearTable()
    @datatable.fnDestroy()
