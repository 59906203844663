import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['master', 'index']

  setVictims: () ->
    { data } = this
    show_victim = data.get 'victimShow'
    hidden_victim = data.get 'victimHidden'
    type_estate = data.get 'typeEstate'
    id = @masterTarget.value
    if (id != type_estate)
      $(hidden_victim).removeClass("uk-hidden")
      $(show_victim).addClass("uk-hidden")
    else
      $(show_victim).removeClass("uk-hidden")
      $(hidden_victim).addClass("uk-hidden")
    connect: () ->
      checker = parseInt @masterTarget.value
      @setVictims() if checker
