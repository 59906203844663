import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['state1', 'city1', 'state0', 'city0']
  submit: (eve) ->
    { data } = this
    country = data.get 'country'
    eve.preventDefault()
    if document.getElementById('form-address-admin') != null
      form = document.getElementById('form-address-admin')
    else if document.getElementById('form-address') != null
      form = document.getElementById('form-address')
    else if document.getElementById('form-address-complete') != null
      form = document.getElementById('form-address-complete')
    else if document.getElementById('form-address-inscripton') != null
      form = document.getElementById('form-address-inscripton')
    same_address = document.getElementById('customer_addresses_attributes_1_same_address');
    country0 = document.getElementById('customer_addresses_attributes_0_country_id').value
    country1 = document.getElementById('customer_addresses_attributes_1_country_id').value
    state0 = null
    state1 = null
    city0 = null
    city1 = null

    if JSON.parse same_address.ariaExpanded
      if country0 == country && country1 == country
        state0 = document.getElementById('select-state0').value
        state1 = document.getElementById('select-state1').value
        city0 = document.getElementById('select-city0').value
        city1 = document.getElementById('select-city1').value
      else if country0 == country && country1 != country
        state0 = document.getElementById('select-state0').value
        state1 = @state1Target.value
        city0 = document.getElementById('select-city0').value
        city1 = @city1Target.value
      else if country0 != country && country1 == country
        state0 = @state0Target.value
        state1 = document.getElementById('select-state1').value
        city0 = @city0Target.value
        city1 = document.getElementById('select-city1').value
      else
        state0 = @state0Target.value
        state1 = @state1Target.value
        city0 = @city0Target.value
        city1 =  @city1Target.value
    else
      if country0 == country
        state0 = document.getElementById('select-state0').value
        city0 = document.getElementById('select-city0').value
      else
        state0 = @state0Target.value
        city0 = @city0Target.value
    document.getElementById('customer_addresses_attributes_0_state').value = state0
    document.getElementById('customer_addresses_attributes_1_state').value = state1
    document.getElementById('customer_addresses_attributes_0_city').value = city0
    document.getElementById('customer_addresses_attributes_1_city').value = city1
    form.submit()
