import Vue from 'vue/dist/vue.esm'
import SecureLS from 'secure-ls'
import { debounce } from 'throttle-debounce'
import UiKit from 'uikit'
import axios from 'axios'
import Mercadolibre from './mercadolibre'

# ANCHOR TURBOLINKS LOAD
window.addEventListener 'turbolinks:load', ->
  el = document.getElementById('mercadolibre-component')
  return false unless el

  uid = el.dataset.uid
  return false unless uid

  product = el.dataset.productId
  category = el.dataset.category
  meli = initMercadolibre(el.dataset)
  cleanDatas(el)
  
  # ANCHOR VUE INIT
  new Vue {
    el
    data: {
      uid: uid
      step: if product then 2 else 1
      category: category
      categories: []
      listingTypes: []
      loading: false
      selectedProduct: product
      searchProducts: []
      productDescription: ''
      meliProductsOverview: {}
      meliProducts: []
      meliUser: false
      publishError: false
      keyword: ''
      finalProduct:
        # TODO AJUSTAR ITEM
        title: '',
        category_id: 'MCO9356', # MONEDAS
        # category_id: 'MCO1953', # OTROS
        price: 0,
        currency_id: 'COP',
        available_quantity: 1,
        buying_mode: 'buy_it_now',
        condition: 'used',
        listing_type_id: 'free',
        pictures: [],
      responseProduct: {}
    }

    # ANCHOR METHODS
    methods:
      saveMeliItem: (meli_id) ->
        response = await @execute axios.post(
          "/admin/#{@uid}/external_stores/store_meli_item.json",
          {
            authenticity_token: $('meta[name="csrf-token"]').attr("content")
            meli_item: 
              product_id: @selectedProduct,
              meli_id: meli_id,
              kreator: @meliUser.nickname,
              permalink: @responseProduct.permalink,
              thumbnail: @responseProduct.thumbnail,
              status: @responseProduct.status,
              meli_quantity: @responseProduct.available_quantity,
              meli_sold_quantity: @responseProduct.sold_quantity
          }
        )

      getProduct: ->
        return false unless @selectedProduct
        response = await @execute axios.get(
          "/admin/#{@uid}/products/#{@selectedProduct}.json"
        )
        {category, pictures, purchase_price, title, units_available} = response?.data
        @category = category
        @finalProduct.title = title
        @finalProduct.price = purchase_price
        @finalProduct.available_quantity = units_available
        @finalProduct.pictures = pictures

      getCategories: ->
        @categories = await @execute meli.getCategories(
          keyword: @category
        )

      getMeliUser: ->
        @meliUser = await @execute meli.getUser()

      getMeliProducts: ->
        @meliProductsOverview = await @execute meli.getUserProducts()

      getListingTypes: -> 
        @listingTypes = await @execute meli.getListingTypes()
        
      publishProduct: ->
        response = await @execute meli.publishProduct(@finalProduct)
        if response.id
          @responseProduct = response
          @saveMeliItem response.id
          @getMeliProducts()
          @publishDescription() if @productDescription
        else
          @prevStep()

      publishDescription: ->
        response = await @execute meli.publishDescription(
          @productDescription, @responseProduct.id
        )

      execute: (action) ->
        @loading = true
        response = await action
        @loading = false
        response

      setSelectedProduct: (value) -> @selectedProduct = value
      authenticate: -> meli.getToken(product)
      setLoading: -> @loading = true
      nextStep: -> @step++ if @step < 3
      prevStep: ->
        @selectedProduct = undefined
        @step-- if @step > 1

    # ANCHOR MOUNTED
    mounted: ->
      if @selectedProduct and @step is 2
        await @getProduct() 
        await @getListingTypes()
        await @getCategories()
      UiKit.modal('#mercadolibre-product-form').toggle() if @selectedProduct
      @getMeliProducts()
      @getMeliUser()

    # ANCHOR WATCHERS
    watch:
      step: (val) ->
        switch val  
          when 2
            await @getProduct()
            await @getListingTypes()
            await @getCategories()
          when 3
            @publishProduct()
      keyword: debounce 700, (val) ->
        return false unless val

        axios.get(
          "/admin/#{@uid}/products/search.json", params: {q: val}
        ).then (response) =>
          @loading = false
          @searchProducts = response.data

    computed:
      canPublish: ->
        return true if not @selectedProduct and @step is 1
        return true if @finalProduct.available_quantity > @maximumQuantity

        false
      maximumQuantity: ->
        if @finalProduct.listing_type_id is 'free' then 1 else 9999
  }

# ANCHOR INIT MERCADOLIBRE
initMercadolibre = ({productId, meliId, redirectUrl, secret, appId, moken}) ->
  new Mercadolibre {meliId, redirectUrl, secret, appId, moken}
  
# ANCHOR CLEAN DATAS
cleanDatas = (el) -> 
  delete el.dataset.moken
  delete el.dataset.uid
  delete el.dataset.meliId
  delete el.dataset.productId
  delete el.dataset.redirectUrl
  delete el.dataset.secret
  delete el.dataset.appId
