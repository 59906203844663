import { Controller } from "stimulus"
import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls'

export default class extends Controller
  otherPayment: ->
    lot = @data.get('lot')
    cart = @data.get('cart')
    uid = @data.get('uid')
    total = @data.get('total')
    if cart
      AxiosRequest("other_payment", {cart, uid, total}, 'post').then ({data}) ->
        ls = new SecureLS()
        ls.remove('cart')
        # borramos el promocionalcode cuando todo termine
        ls.remove('discount')
        Swal.fire({
          icon: 'success'
          title: data.message
          showConfirmButton: false
          timer: 8000
          timerProgressBar: true
        }).then ->
          window.location.replace data.url
    else if lot
      AxiosRequest("/customers/#{uid}/lots/other_payment", {lot, uid, total}, 'post').then ({data}) ->
        Swal.fire({
          icon: 'success'
          title: data.message
          showConfirmButton: false
          timer: 8000
          timerProgressBar: true
        }).then ->
          window.location.replace data.url
