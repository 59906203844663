import {Controller} from "stimulus"
import SecureLS from 'secure-ls';
import _ from 'lodash'

export default class extends Controller
  @targets = ['output']
  connect: ->
    acum = 0
    ls = new SecureLS()
    cart = ls.get 'cart'
    final_cart = {}
    uid = parseInt @.data.get('uid')
    if cart == ''
      @outputTarget.textContent =  acum
    else
      # carrito sin session
      final_cart['joker'] = cart['joker'] if cart['joker']
      joker_cart = _.map final_cart['joker'], (elem) => [elem. idproduct]
      local_cart = {}
      # Comprobar si hay usuario
      if uid
        final_cart[uid] = cart[uid]
        local_cart = _.map final_cart[uid], (elem) => [elem. idproduct]
        final_cart =  _.unionWith [local_cart..., joker_cart...], _.isEqual
      else
        final_cart = joker_cart
      for item in final_cart
        acum++
      @outputTarget.textContent =  acum
