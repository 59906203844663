import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

application = Application.start()
context = require.context('controllers/admin', true, /_controller\.js|.coffee$/)
contextComponents = require.context("../../../components", true, /_controller\.js|.coffee$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)
